.search-box {
    padding: 1.5vh;
    width: 90%;
    align-self: center;
    color: white;
    border-radius: 1vh;
    font-family: inherit;
    font-size: 1.2em;
    max-width: 400px;
    background-color: #243647;
    border: unset;
    outline: none;
}

.search-box::placeholder {
 color: #93ADC8;
}