.gifs-container {
    width: 95%;
}

.gif-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2vw;
    place-items: center;
}

.gif-container {
    margin: 1vh;
    text-align: center;
    position: relative;
    cursor: pointer;

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.gif {
    transition: .5s ease;
    border-radius: 1vh;
    border: solid 1px #243647;
    width: 100%;
    height: 100%;
}

.gif-input-text {
    all: unset;
    top: 5%;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    color: white;
    cursor: text;
}

.gif-download {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    width: 30%;
    height: 30%;
    /** Center image */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.gif-metadata {
    text-align: left;
    margin: 1%;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.gif-container:hover .gif {
    opacity: 0.5;
}

.gif-container:hover .gif-download {
    opacity: 1;
}

.gif-container:hover .gif-metadata {
    opacity: 1;
}

.swipe {
    opacity: 0;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    z-index: 1000;
    pointer-events: none;
}

.gif-container:first-child .swipe {
    opacity: 1;
}

.path {
    width: 20px;
    height: 20px;
    position: absolute;
    background: rgba(149, 147, 241, 0.5);
    border-radius: 40px;
    left: 50px;

    visibility: hidden;
    animation: swipe-dot 2s 0.5s 5;
}

.hand-icon {
    position: relative;
    background-image: url('https://i.postimg.cc/8556gm60/hand.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    
    visibility: hidden;
    transform-origin: 52% 62%;
    animation: swipe-hand 2s 5;
}

@keyframes swipe-hand {
    0% { visibility: visible }
    25% { transform: translate(20px) rotate(30deg); }
    50% { transform: translate(-20px) rotate(-15deg); }
    100% { visibility: visible; transform: translate(0px) rotate(0); }
}

@keyframes swipe-dot {
  20% {
    visibility: visible;
    width: 40px;
  }
  25% {
    /* visibility: visible; */
    transform: translate(-65px);
    width: 20px;
  }
  26% { visibility: hidden; }
}

@keyframes fadeInAnimation {
    0% { opacity: 0 }
    100% { opacity: 1 }
}