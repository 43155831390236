.filter {
    align-self: flex-start;
    min-width: 20vw;
}
.filter .react-select__control {
    min-height: unset;
    border: unset;
    box-shadow: unset;
    border-radius: 1vh;
    background-color: #243647;
}

.filter .react-select__input-container {
  color: white;  
}

.filter .react-select__placeholder {
  color: #93ADC8;
}

.filter .react-select__indicator {
  padding: 1vh;
  color: #93ADC8;
}

.filter .react-select__indicator:hover {
  color: white;
}

.filter .react-select__indicator-separator {
  background-color: #93ADC8;
}

.filter .react-select__value-container {
  height: 100%;
}

.filter .react-select__indicators {
  height: 100%;
  padding: unset;
  margin: auto;
}

.filter .react-select__multi-value {
  background-color: #93ADC8;
}

.filter .react-select__multi-value__label {
  color: black;
}

.filter .react-select__menu {
  background-color: #282c34;
  color: #ffffff;
  margin: unset;
  width: 100%;
}

.filter .react-select__menu-list::-webkit-scrollbar {
  width: 0px;
}

.filter .react-select__option--is-selected {
  background-color: #007bff;
  color: #ffffff;
}

.filter .react-select__option--is-focused {
  background-color: #0056b3;
  color: #ffffff;
}