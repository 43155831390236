.buy-me-coffee {
    opacity: 1 !important;
    visibility: visible !important;
    padding: 0px !important;
    background-color: transparent !important;
}

.buy-me-coffee:after {
	content: '';
	position: absolute;
	right: 0;
	bottom: 15%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left-color: #243647;
	border-right: 0;
	margin-top: -20px;
	margin-right: -20px;
}