body {
  text-align: center;
  background-color: #111a22;
  min-height: 100vh;
  font-family: monospace;
  font-size: max(1em, 16px);;
  color: white;
  overflow-x: hidden;
}

.App-search {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 1vh;
  width: 90%;
  margin: 5vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding: 0 8vw 0 8vw;
  cursor: default;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  font-size: 2em;
  border-bottom: solid 1px #243647;
}

.App-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* remove default link styling */
a {
  color: inherit;
  text-decoration: inherit;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #363636;
}